let key = {};
if (process.env.REACT_APP_MODE == "production") {
  console.log = function () {};

  console.log("Set Production Config");
  const API_URL = "https://producationapi.mudra.exchange";

  key = {
    SITE_NAME:"Mudra Exchange - Bharat's Own Cryptocurrency Exchange",
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LebygErAAAAAE5m3OLdUY1cnkZxilAcdQHBOBpU", //MUDRAEX
    API_URL: "https://producationapi.mudra.exchange",
    FRONT_URL: "https://mudra.exchange/",
    // API_URL: "http://54.151.2.168:2053",
    // FRONT_URL: "http://54.151.2.168",
    ADMIN_URL: "https://producationapi.mudra.exchange",
    SOCKET_URL: "https://producationapi.mudra.exchange",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/mudra",
      twitterUrl: "https://www.twitter.com/mudra",
      linkedinUrl: "https://www.linkedin.com/mudra",
      redditUrl: "https://www.reddit.com/mudra",
      youtubeUrl: "https://www.youtube.com/mudra",
    },
    exchangeurl: "https://testico.mudra.exchange/home/",
    // zaakpayurl:"https://api.zaakpay.com"
    // 'https://ico.mudra.exchange',
    zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",
    // zaakpayurl:"https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "2955a6b7be264d07ac7aa525bc560c17",
    returnUrl: "https://producationapi.mudra.exchange/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "e7d6d03069c448e685b1ce0237f37aed",
  };
} else if (process.env.REACT_APP_MODE === "development") {
  console.log("Set dev env Config");
  const API_URL = "https://testproducationapi.mudra.exchange";

  key = {
    SITE_NAME:"Mudra Exchange - Bharat's Own Cryptocurrency Exchange",
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LebygErAAAAAE5m3OLdUY1cnkZxilAcdQHBOBpU", //MUDRAEX
    // RECAPTCHA_SITE_KEY: "6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi",
    API_URL: "https://testproducationapi.mudra.exchange",
    FRONT_URL: "https://test.mudra.exchange",
    ADMIN_URL: "https://testadmin.mudra.exchange",
    SOCKET_URL: "https://testproducationapi.mudra.exchange",
    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/mudra",
      twitterUrl: "https://www.twitter.com/mudra",
      linkedinUrl: "https://www.linkedin.com/mudra",
      redditUrl: "https://www.reddit.com/mudra",
      youtubeUrl: "https://www.youtube.com/mudra",
    },
    exchangeurl: "https://testico.mudra.exchange/home/",
    // zaakpayurl:"https://api.zaakpay.com"
    // 'https://ico.mudra.exchange',
    zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",
    // zaakpayurl:"https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "2955a6b7be264d07ac7aa525bc560c17",
    returnUrl: "https://testproducationapi.mudra.exchange/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "e7d6d03069c448e685b1ce0237f37aed",
  };
} else {
  console.log("Set Development Configssssssssssssssssssssssssssssss");
  const API_URL = "https://producationapi.mudra.exchange"; //http://192.168.29.63:3000/  http://localhost
  // const API_URL = "http://localhost"
  key = {
    SITE_NAME:"Mudra Exchange - Bharat's Own Cryptocurrency Exchange",
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LebygErAAAAAE5m3OLdUY1cnkZxilAcdQHBOBpU",
    API_URL: `${API_URL}`,
    SOCKET_URL: `${API_URL}`,
    FRONT_URL: "https://test.mudra.exchange",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/mudra",
      twitterUrl: "https://www.twitter.com/mudra",
      linkedinUrl: "https://www.linkedin.com/mudra",
      redditUrl: "https://www.reddit.com/mudra",
      youtubeUrl: "https://www.youtube.com/mudra",
    },
    exchangeurl: "https://testico.mudra.exchange/home/",
    zaakpayurl: "https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "fb2016ffd3a64b2994a6289dc2b671a4",
    returnUrl: "https://testproducationapi.mudra.exchange/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "0678056d96914a8583fb518caf42828a",
  };
}

export default key;

// 6Lcz48IbAAAAAJffeGirGcjkr3PIKO4sZxBaf66G - secret key

// 6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi - site key
 