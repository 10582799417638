/*eslint-disable*/
import React from "react";
import { Link, useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { logout } from "../../actions/users";
import {  Button, Menu, MenuItem } from "@material-ui/core";
import key from '../../config/index'

import { useDispatch, useSelector } from "react-redux";
import {  setTheme } from "../../actions/commonAction";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

const HeaderLinks = () => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();

  // redux-state
  const { isAuth } = useSelector((state) => state.auth);
  const themeData = useSelector((state) => state.theme);

  const { profile } = useSelector((state) => state.account);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="home_page_menu">
      <List className={classes.list + " menu_main_navbar"}>
        <ListItem className={classes.listItem}>
          <div className="toggleMode" title="toggle dark mode">
            <label>
              <input
                type="checkbox"
                checked={themeData == "light" ? true : false}
                name=""
                onClick={() =>
                  setTheme(dispatch, themeData == "light" ? "dark" : "light")
                }
              />
              <span></span>
            </label>
          </div>
        </ListItem>

        <ListItem className={classes.listItem}>
          <Link rel="preload" to="/spot" color="transparent" className="nav-link">
            Spot
          </Link>
        </ListItem>

        {/* <ListItem className={classes.listItem}>
          <Link to="/peertopeer" color="transparent" className="nav-link">P2P</Link>
        </ListItem>         */}

        {isAuth && (
          <ListItem className={classes.listItem}>
            <Link rel="preload" to="/wallet" color="transparent" className="nav-link">
              Wallet
            </Link>
          </ListItem>
        )}
        {isAuth && (
          <li>
            <Button
              aria-controls="profile_menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              {/* <i className="fas fa-user profile_user"></i> */}
              <div className="profile_img_holder_nav">
                  { profile && profile?<img src={key.API_URL+'/profile/'+profile} alt="Profile"/>:<img src={require("../../assets/images/team_04.webp").default} alt="Profile"/>}
                  </div>
            </Button>
            <Menu
              id="profile_menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                <Link rel="preload" to="/profile">Profile</Link>
              </MenuItem>
              <MenuItem>
                <Link rel="preload" to="/kyc">KYC</Link>
              </MenuItem>
              <MenuItem>
                <Link rel="preload" to="/settings">Settings</Link>
              </MenuItem>
              <MenuItem>
                <Link rel="preload" to="/history">History</Link>
              </MenuItem>
              <MenuItem>
                <Link rel="preload" to="/support-ticket">Support</Link>
              </MenuItem>
              <MenuItem>
                <Link to="#" onClick={() => logout(history, dispatch)}>
                  Logout
                </Link>
              </MenuItem>
            </Menu>
          </li>
        )}

        {!isAuth && (
          <ListItem className={classes.listItem}>
            <Link
              to="/login"
              color="transparent"
              className="nav-link home_menu_btn"
            >
              Login
            </Link>
          </ListItem>
        )}

        {!isAuth && (
          <ListItem className={classes.listItem}>
            <Link
              to="/register"
              color="transparent"
              className="nav-link home_menu_btn"
            >
              Register
            </Link>
          </ListItem>
        )}
      </List>
    </div>
  );
};

export default HeaderLinks;
